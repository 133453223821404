@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;600;700;800&display=swap");

*{
  margin: 0;
  padding: 0;
}

html, body{
  height: 100vh;
}

.ant-layout{
  height: 100vh;
}

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.site-layout .site-layout-background {
  background: #fff;
}

/* Dashboard graphics */

.graphic-main {
  background-color: #FFF;
}

.graphic-main h1 {
  color: #555;
}

.ant-btn{
  align-items: center;
  justify-content: center;
  align-content: 'center';
}

.ant-layout-header{
  position: fixed; 
  z-index: 9; 
  width: 100%; 
  padding: 0 !important; 
  display: flex; 
  justify-content: flex-start; 
  align-content: center; 
  align-items: center;
}

.logo{
  max-width: 100%;
  height: 31px;
  margin: 16px 24px;
  background: rgba(255, 255, 255, 0.3);
}

.schedule-status-PENDENT{
  border-left: 4px solid rgb(251, 191, 36)
}
.schedule-status-CANCELLED{

  border-left: 4px solid rgb(248, 113, 113)
}
.schedule-status-CONFIRMED{

  border-left: 4px solid rgb(52, 211, 153)
}

.ant-popover-buttons{
  display: flex !important;
}

.fc-toolbar-title{
  text-transform: capitalize;
}

.custom-tooltip{
  border: 1px solid gray;
  border-radius: 8px;
  background-color: #fff;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: left;
}
